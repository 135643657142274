import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
const SuccessRegister = () => (
  <Result
    status="success"
    title="Registrado exitosamente"
    subTitle="Usuario registrado correctamente inicie sesión."
    extra={[
      <Link to='/auth/login'>
      <Button  style={{backgroundColor: '#1976d2', color: "white"}} >
      Iniciar sesión
      </Button>,
      </Link>
    ]}
  />
);
export default SuccessRegister;
import styles from "./Support.module.css";
import image from "../../image/trading2png.avif";
import * as React from "react";
import { useTheme } from "@mui/material/styles";

import Identify from "../Identify/Identify";
import CircularProgress from "@mui/material/CircularProgress";
import { SuportSend } from "../../Redux/action";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";

export default function Support() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  const theme = useTheme();

  const [data, setData] = React.useState({
    email: "",
    affair: "",
    message: "",
  });
  const names = [
    "Preguntas relacionadas con depósito/retiros",
    "Preguntas relacionadas con verificacíon de cliente",
    "Preguntas relacionadas con operaciones",
    "Problemas técnicos",
    "Solicitar una llamada telefónica",
    "Otro",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await dispatch(SuportSend(data));
    } catch (error) {
      alert("Error al comunicarse");
      console.log(error);
    }
  };
  const handleChange = (event) => {
    event.preventDefault();

    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  React.useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <Identify />
          <div className={styles.SupportContainer}>
            <div>
              <h1>Soporte al cliente</h1>
              <img src={image} alt="" />
            </div>

            <div className={styles.checkContainer}>
              <div>
                <a href="https://wa.me/+541166783635">
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: "green",
                      ":hover": {
                        backgroundColor: "green",
                      },
                    }}
                  >
                    <WhatsAppIcon />
                    WhatsApp
                  </Button>
                </a>
              </div>
              <div>
                <a href="https://t.me/Atradinginversiones">
                  <Button variant="contained" type="submit" sx={{}}>
                    <TelegramIcon />
                    Telegram
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

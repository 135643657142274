import { useState, useEffect } from "react";
import styles from "./Withdraw.module.scss";
import Identify from "../Identify/Identify";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { Wallet, DataPersonal } from "../../Redux/action";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { Input, Checkbox } from "antd";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import axios from "axios";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Withdraw() {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const dataPersonal = useSelector((state) => state.dataPersonal);
  const token = useSelector((state) => state.token);
  const [data, setData] = useState({
    name: "",
    lastName: "",
    email: "",
    amount: 0,
    ruc: "",
    accountNumber: "",
  });

  const [loading, setLoading] = useState(true);
  const [agreement, setAgreement] = useState(false);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      name: dataPersonal.name,
      lastName: dataPersonal.lastName,
      email: dataPersonal.email,

      accountNumber: dataPersonal.accountNumber,
    }));
  }, []);
  useEffect(() => {
    setTimeout(async () => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    dispatch(Wallet(token));
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(DataPersonal(token));
  }, [dispatch, token]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckboxChange = (e) => {
    setAgreement(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreement) {
      alert("Debe aceptar los términos y condiciones.");
      return;
    }
    try {
      const response = await axios.post(
        "https://x-power-trade-production.up.railway.app/retirar",
        data
      );
      if (response.data.success) {
        alert("Solicitud de retiro enviada con éxito");
      } else {
        alert("Hubo un problema al enviar la solicitud de retiro");
      }
    } catch (error) {
      console.error("Error al enviar la solicitud de retiro:", error);
      alert("Error al enviar la solicitud de retiro");
    }
  };

  const formattedBalance =
    wallet && wallet.balance !== null && typeof wallet.balance !== "undefined"
      ? wallet.balance.toFixed(2)
      : "";

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <Identify />
          <div className={styles.withdrawContainer}>
            <h2>Instrucciones para Transferencias y Retiros</h2>

            <div className={styles.list}>
              <ul>
                <li>
                  Para realizar una transferencia, contacte con nuestros
                  asesores y proporcione el número de cuenta de la persona a la
                  que se le realizará la transferencia. Para realizar un retiro,
                  es necesario tener un historial de depósito mínimo de $50
                   USD.
                  Nuestro equipo de asesores está disponible para guiarlo y
                  asegurar que todos los procesos se realicen de manera rápida y
                  segura.
                </li>
              </ul>
            </div>
            <div className={styles.checkContainer}>
              <div>
                <a href="https://wa.me/+541166783635">
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: "green",
                      ":hover": {
                        backgroundColor: "green",
                      },
                    }}
                  >
                    <WhatsAppIcon />
                    WhatsApp
                  </Button>
                </a>
              </div>
              <div>
                <a href="https://t.me/Atradinginversiones">
                  <Button variant="contained" type="submit" sx={{}}>
                    <TelegramIcon />
                    Telegram
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
